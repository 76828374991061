import React from "react";
import { images } from "../javascript/imageImports";

const ChallengeDetails = () => {
  return (
    <section id="challengedetails" className="my-5 p-2 p-md-4 scroll-margin overflow-hidden">
      {/* Global overflow control */}
      <style>
        {`
          html, body {
            overflow-x: hidden;
            max-width: 100%;
          }
        `}
      </style>

      <div className="container">
        <div className="row mb-4">
          <div className="col-lg-8 mx-auto text-center">
            <h2 className="topic display-6 section-heading text-warning text-uppercase">
              IESL RoboGames 2024 Categories
            </h2>
            <hr className="light my-4" />
          </div>
        </div>

        {/* Two-column layout for School and Undergraduate categories */}
        <div className="row mb-5 g-4">
          {/* School Challenge Card */}
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <div className="card bg-dark h-100 reveal-left" style={{ 
              border: '1px solid rgba(255, 255, 255, 0.2)', 
              borderRadius: '10px',
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer" 
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.02)";
              e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "none";
            }}>
              <div className="card-header bg-dark text-center py-3">
                <h2 className="m-0 text-uppercase text-warning">School Challenge</h2>
              </div>
              <div className="card-body p-4">
                <p className="my-3 text-light" style={{ textAlign: "justify" }}>
                  Prepare yourselves for an engaging competition structured in three phases. The
                  initial two segments involve online participation through the utilization of the Webots
                  robot simulation software, enabling control over virtual robots. In the subsequent and
                  concluding phase, an in-person gathering will transpire. Notably, participants won't
                  need to physically construct robots, as we'll supply the necessary robotic entities.
                  Instead, the objective lies in successfully programming these robots to execute
                  specific movements within a designated play area that we will provide.
                </p>
                <div className="text-center mt-4">
                  <a
                    className="neon-button-three btn p-3 px-5 btn-lg reveal-left"
                    href="https://docs.google.com/document/d/12Z3MFy8um1E2Be1w21bfXgmNmXZal27Y4ZVP0qrZ9Kg/edit?usp=drivesdk"
                    target="_blank"
                    rel="noreferrer"
                    style={{ transition: "transform 0.2s ease" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <span></span><span></span><span></span><span></span>
                    More Details...
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Undergraduate Challenge Card */}
          <div className="col-12 col-md-6 mb-4 mb-md-0">
            <div className="card bg-dark h-100 reveal-right" style={{ 
              border: '1px solid rgba(255, 255, 255, 0.2)', 
              borderRadius: '10px',
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer" 
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.02)";
              e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "none";
            }}>
              <div className="card-header bg-dark text-center py-3">
                <h2 className="m-0 text-uppercase text-warning">Undergraduate Challenge</h2>
              </div>
              <div className="card-body p-4">
                <p className="my-3 text-light" style={{ textAlign: "justify" }}>
                  Prepare yourselves for an engaging competition structured in three phases. The
                  initial two segments involve online participation through the utilization of the Webots
                  robot simulation software, enabling control over virtual robots. In the subsequent and
                  concluding phase, an in-person gathering will transpire. Notably, participants won't
                  need to physically construct robots, as we'll supply the necessary robotic entities.
                  Instead, the objective lies in successfully programming these robots to execute
                  specific movements within a designated play area that we will provide.
                </p>
                <div className="text-center mt-4">
                  <a
                    className="neon-button-three btn p-3 px-5 btn-lg reveal-left"
                    href="https://docs.google.com/document/d/1u_KjnrSA-jPuiUvvTb6JFBh4OotIM2OZtLvOz6ONp78/edit?usp=drivesdk"
                    target="_blank"
                    rel="noreferrer"
                    style={{ transition: "transform 0.2s ease" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = "scale(1.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <span></span><span></span><span></span><span></span>
                    More Details...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Open Challenge Card - Full width below */}
        <div className="row mt-5">
          <div className="col-12">
            <div className="card bg-dark reveal-bottom" style={{ 
              border: '1px solid rgba(255, 255, 255, 0.2)', 
              borderRadius: '10px',
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
              cursor: "pointer" 
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "scale(1.02)";
              e.currentTarget.style.boxShadow = "0 8px 15px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "none";
            }}>
              <div className="card-header bg-dark text-center py-3">
                <h2 className="m-0 text-uppercase text-warning">Open Challenge - Battlebots</h2>
              </div>
              <div className="card-body p-4">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <p className="my-3 text-light" style={{ textAlign: "justify" }}>
                      Gear up for an electrifying showdown in the Open Category, featuring the highly
                      anticipated Battlebots Challenge. This segment is designed to test your engineering
                      acumen, strategic planning, and competitive spirit in a no-holds-barred knockout
                      tournament.
                    </p>
                    <p className="my-3 text-light" style={{ textAlign: "justify" }}>
                      Participants are invited to design and build their own combat robots, adhering to the
                      competition's technical specifications and safety regulations. The tournament will
                      culminate in thrilling Grand Finale Day, where these robots will face off in
                      head-to-head battles, aiming to outmaneuver, outwit, and overpower their opponents.
                    </p>
                    <p className="my-3 text-light" style={{ textAlign: "justify" }}>
                      The challenge is simple yet intense: construct a robot capable of dominating the
                      arena, surviving fierce clashes, and emerging as the ultimate champion. Victory
                      hinges on the ingenuity of your design, the efficiency of your programming, and your
                      ability to strategize under pressure.
                    </p>
                    <div className="text-center mt-4">
                      <a
                        className="neon-button-three btn p-3 px-5 btn-lg reveal-left"
                        href="https://docs.google.com/document/d/1fwg8040b7bO62LUIAcw_0AMLn5iADH7ny26bOV0Q82U/edit?usp=drivesdk"
                        target="_blank"
                        rel="noreferrer"
                        style={{ transition: "transform 0.2s ease" }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "scale(1.1)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                        }}
                      >
                        <span></span><span></span><span></span><span></span>
                        More Details...
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        
      {/* Background image container */}
      <div className="fixed-wrap d-none d-lg-block position-absolute top-0 start-0 w-100 h-100" style={{ zIndex: "-1" }}>
        <div
          className="fixed opacity-25"
          style={{ backgroundImage: `url(${images.fixedBackground})` }}
        ></div>
      </div>
    </section>
  );
};

export default ChallengeDetails;
