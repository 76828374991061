import logo from '../images/logos/RoboGames Logo.png';

import background from '../images/wallpapersden.com_cool-4k-pattern_right_bottom.jpg';
import bumblebee from '../images/bumblebee.png';
import fixedBackground from '../images/fixed_background.jpg';
import dashboard_bg from '../images/polygon-scatter.svg';

import img_1 from '../images/portfolio/fullsize/1.jpg';
import img_2 from '../images/portfolio/fullsize/2.jpg';
import img_3 from '../images/portfolio/fullsize/3.jpg';
import img_4 from '../images/portfolio/fullsize/4.jpg';
import img_5 from '../images/portfolio/fullsize/5.jpg';
import img_6 from '../images/portfolio/fullsize/6.jpg';

import img_11 from '../images/portfolio/2022/1.jpg'
import img_12 from '../images/portfolio/2022/2.jpg'
import img_13 from '../images/portfolio/2022/3.jpg'
import img_14 from '../images/portfolio/2022/4.jpg'
import img_15 from '../images/portfolio/2022/5.jpg'
import img_16 from '../images/portfolio/2022/6.jpg'

import img_17 from '../images/portfolio/2023/1.jpg'
import img_18 from '../images/portfolio/2023/2.jpg'
import img_19 from '../images/portfolio/2023/3.jpg'
import img_20 from '../images/portfolio/2023/4.jpg'
import img_21 from '../images/portfolio/2023/5.jpg'


import img_7 from '../images/portfolio/awards2020/DSC_7718.JPG';
import img_8 from '../images/portfolio/awards2020/Schl_3rd_TRC.JPG';
import img_9 from '../images/portfolio/awards2020/Uni_1st_CircuitBreakers.JPG';
import img_10 from '../images/portfolio/awards2020/Uni_3rd_PitchBlack.JPG';

import optimus from '../images/optimus.png';

import iesl from '../images/logos/iesl.gif';
import uom from '../images/logos/uom.png';
import cse from '../images/logos/cse.jpg';
import slt from '../images/logos/slt_with_background.png';

import first from '../images/first.png';
import second from '../images/second.png';
import third from '../images/third.png';
import Thumbnail2 from '../images/Thumbnail2.jpg';
import cup from '../images/Leaderboard_cup.png';

import gold from '../images/trophies/gold.png';
import silver from '../images/trophies/silver.png';
import bronze from '../images/trophies/bronze.png';
import landing from '../images/landing.jpg';
import robotarm from "../images/robotarm.jpg";
import robo from "../images/robo.png";

export const images =  {
    logo,
    background, dashboard_bg,
    bumblebee,
    fixedBackground,
    img_1, img_2, img_3, img_4, img_5, img_6, img_7, img_8, img_9, img_10,
    img_11, img_12, img_13, img_14, img_15, img_16,img_17,img_18,img_19,img_20,img_21,
    optimus,
    iesl, uom, cse, slt,
    first, second, third, Thumbnail2, cup,
    gold, silver, bronze,
    landing,
    robotarm,
    robo,
 

}