import React, { useState, useEffect } from "react";
//import { Link } from "react-router-dom";

const CountDown = () => {
  const calculateTimeLeft = () => {
    let countDownDate = new Date("Mar 30, 2025 09:00:00").getTime();

    let now = new Date().getTime();

    let difference = countDownDate - now;

    let timeleft = { finished: true };

    if (difference > 0) {
      timeleft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
        finished: false,
      };
    }

    return timeleft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <section id="register" className="my-5 p-4 scroll-margin">
      <div className="container justify-content-center text-center">
        <h2 className=" topic section-heading lead fw-bold display-3 reveal-bottom      text-uppercase text-white">
          Dive into the Excitement of IESL Robogames 2024!
        </h2>

        <div className="col-10 col-lg-8 mx-auto my-5">
          <figure>
            <blockquote className="blockquote">
              <p className="my-2 topic lead fw-light display-7   reveal-bottom     text-info">
                Seize this opportunity to be part of the ultimate robotics
                challenge!
              </p>
            </blockquote>
          </figure>
        </div>
        <div>
          <p className="display-7 reveal-bottom text-white lead">(For open category only)</p>
        </div>
        <a
          className={`neon-button btn btn-outline-warning p-3 px-5 my-5 mx-auto reveal-flip ${
            !timeLeft.finished ? "" : "d-none"
          }`}
          href="https://forms.gle/d6tiQoePXFb7VwYH8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Register Now!!!
        </a>

        {/* <div className="row text-center">
        {/* School Category */}
        {/* <div className="col-lg-6 text-muted reveal-bottom">
          <h2 className="topic display-6 section-heading text-white text-uppercase">
            School <br></br> Category
          </h2>
          <hr />
          <a
            className="neon-button btn btn-outline-warning p-3 px-5 my-5 mx-auto reveal-flip"
            href="https://drive.google.com/file/d/1Zfotn73G376tyqTOIC9Lgg6eLEucxLWx/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Task
          </a>
          <hr />
          <a
            className="neon-button btn btn-outline-warning p-3 px-5 my-5 mx-auto reveal-flip"
            href="https://drive.google.com/file/d/1Ek0C-U2GFWZyNufDuGNV6V9rM5qXolNx/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Webots Environment
          </a>
        </div> */}

        {/* University Category */}
        {/* <div className="col-lg-6 text-muted reveal-bottom">
          <h2 className="topic display-6 section-heading text-white text-uppercase">
            University Category
          </h2>
          <hr />
          <a
            className="neon-button btn btn-outline-warning p-3 px-5 my-5 mx-auto reveal-flip"
            href="https://drive.google.com/file/d/1kCbMtWUNTgEXV-U-v-JPl-ObygvCCkeb/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Task
          </a>
          <hr />
          <a
            className="neon-button btn btn-outline-warning p-3 px-5 my-5 mx-auto reveal-flip"
            href="https://drive.google.com/file/d/1Bn2IL2NMc5XWVJzuLlXdSLcrInM_RF4U/view?usp=sharing"
            target="_blank"
            rel="noopener noreferrer"
          >
            Webots Environment
          </a>
        </div>
      </div>  */}

        <div className="row my-5">
          <div className="col-lg-8 mx-auto text-center text-muted reveal-bottom">
            <hr />
            <h2 className="clock topic lead section-heading text-danger text-uppercase reveal-rotate">
              {!timeLeft.finished
                ? "Registration Closes In:"
                : "Registration Closed"}
            </h2>
            <hr />
          </div>
        </div>

        <div
          className={`clock-display row mt-5 justify-content-center ${
            !timeLeft.finished ? "" : "d-none"
          }`}
        >
          <div className="col-sm-2 time-circle text-center reveal-rotate">
            <p className="days display-5 text-warning">{timeLeft.days}</p>
            <span className="text-danger">
              {timeLeft.days === 1 ? "day" : "days"}
            </span>
          </div>
          <div className="col-sm-2 time-circle text-center reveal-rotate-400">
            <p className="hours display-5 text-warning">{timeLeft.hours}</p>
            <span className="text-danger">hours</span>
          </div>
          <div className="col-sm-2 time-circle text-center reveal-rotate-600">
            <p className="minutes display-5 text-warning">{timeLeft.minutes}</p>
            <span className="text-danger">minutes</span>
          </div>
          <div className="col-sm-2 time-circle text-center reveal-rotate-800">
            <p className="seconds display-5 text-warning">{timeLeft.seconds}</p>
            <span className="text-danger">seconds</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountDown;
