import React from "react";
import { Link } from "react-router-dom";
import { images } from "../javascript/imageImports";
import FinalCountdown from "./FinalCountdown";

const Header = () => {
  return (
    <header className="text-center d-flex justify-content-center align-items-center text-white min-vh-100 position-relative">
      {/* Background image - centered without blur */}
      <div
        className="position-absolute top-0 start-0 w-100 h-100"
        style={{
          backgroundImage: `url(${images.landing})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          zIndex: 0,
          opacity: 0.5,
        }}
      ></div>

      {/* Black overlay layer */}
      <div
        className="position-absolute top-0 start-0 w-100 h-100"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          zIndex: 1,
        }}
      ></div>

      {/* Content */}
      <div
        className="container position-relative d-flex flex-column justify-content-center align-items-center"
        style={{
          zIndex: 2,
          minHeight: "100vh",
          paddingTop: "80px",
          paddingBottom: "80px",
        }}
      >
        <div className="row justify-content-center align-items-center w-100">
          <div className="col-12">
            {/* Two Column Layout */}
            <div className="row justify-content-between align-items-center mt-4">
              {/* Left Column - Quote */}
              <div className="col-lg-5 mb-4 mb-lg-0">
                <h1
                  className="topic text-uppercase"
                  style={{
                    fontSize: "clamp(2rem, 7vw, 5rem)",
                    fontWeight: "bold",
                    letterSpacing: "2px",
                    marginBottom: "2rem",
                    lineHeight: "1.1",
                  }}
                >
                  IESL ROBOGAMES
                  <br />
                  2024
                </h1>
                <figure className="mx-auto" style={{ maxWidth: "800px" }}>
                  <blockquote className="blockquote">
                    <p
                      className="mb-4"
                      style={{ fontSize: "clamp(1rem, 3vw, 1.25rem)" }}
                    >
                      Embrace the challenges, fuel your passion, and let
                      determination be the spark that powers your journey to
                      victory in IESL RoboGames 2024.
                    </p>
                  </blockquote>
                </figure>
                <div style={{ paddingTop: "20px" }}>
                  <FinalCountdown />
                </div>
              </div>

              {/* Right Column - Battle Bot Section and Robot Arm */}
              <div className="col-lg-6 position-relative">
                {/* Animated Robot Arm Image */}
                <div
                  className="position-absolute d-none d-md-block"
                  style={{
                    top: "-50px",
                    right: "-20px",
                    width: "150px", // Reduced size for better mobile display
                    height: "180px", // Reduced size for better mobile display
                    zIndex: 3,
                    animation: "robotArmMove 4s ease-in-out infinite",
                    transform: "rotate(15deg)",
                  }}
                >
                  {/* Battle Section in Center of Robot Arm */}
                  <div
                    className="position-absolute d-flex justify-content-center align-items-center"
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "60px", // Smaller for mobile
                      height: "60px", // Smaller for mobile
                      borderRadius: "50%",
                      background: "rgba(0, 0, 0, 0.8)",
                      border: "2px solid #FF0000",
                      zIndex: 4,
                      boxShadow: "0 0 8px rgba(255, 0, 0, 0.5)", // Reduced shadow
                      animation: "battlePulse 1.5s infinite alternate",
                    }}
                  >
                    <div className="text-center">
                      <i
                        className="fas fa-meteor text-danger"
                        style={{ fontSize: "1.5rem", rotate: "140deg" }}
                      ></i>
                      <div
                        className="mt-1 fw-bold"
                        style={{ fontSize: "0.6rem", color: "#fff" }}
                      >
                        BATTLE
                      </div>
                    </div>
                  </div>

                  {/* Add battle animations */}
                  <style>
                    {`
        @keyframes battlePulse {
          from { box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); transform: translate(-50%, -50%) scale(1); }
          to { box-shadow: 0 0 10px rgba(255, 0, 0, 0.7); transform: translate(-50%, -50%) scale(1.05); }
        }
        @keyframes rotate {
          from { transform: rotate(0deg); }
          to { transform: rotate(360deg); }
        }
      `}
                  </style>

                  {/* Add robot arm animation */}
                  <style>
                    {`
        @keyframes robotArmMove {
          0% { transform: rotate(15deg) translateY(0); }
          25% { transform: rotate(18deg) translateY(-8px); }
          50% { transform: rotate(20deg) translateY(0); }
          75% { transform: rotate(15deg) translateY(8px); }
          100% { transform: rotate(15deg) translateY(0); }
        }
      `}
                  </style>
                </div>

                {/* Battle Bot Section Information - NEON HIGHLIGHTED */}
                <div
                  className="event-highlight py-3 py-md-4 px-2 px-md-3 mx-auto position-relative"
                  style={{
                    background:
                      "linear-gradient(135deg, rgba(0, 0, 0, 0.8) 0%, rgba(25, 25, 40, 0.9) 100%)",
                    border: "2px solid #00ffff",
                    borderRadius: "8px",
                    textAlign: "left",
                    boxShadow:
                      "0 0 5px #00ffff, 0 0 10px rgba(0, 255, 255, 0.3)", // Reduced shadow
                    transform: "translateZ(0)",
                    animation: "neonPulse 1.5s infinite alternate",
                  }}
                >
                  {/* Add neon pulse animation with reduced intensity */}
                  <style>
                    {`
        @keyframes neonPulse {
          from { box-shadow: 0 0 5px #00ffff, 0 0 10px rgba(0, 255, 255, 0.3); }
          to { box-shadow: 0 0 8px #00ffff, 0 0 15px rgba(0, 255, 255, 0.4); }
        }
        @keyframes textGlow {
          from { text-shadow: 0 0 3px #00ffff, 0 0 5px #00ffff; }
          to { text-shadow: 0 0 5px #00ffff, 0 0 8px #00ffff; }
        }
        @keyframes robotFight {
          0% { transform: translateX(-3px) rotate(-1deg); }
          25% { transform: translateY(-2px) rotate(1deg); }
          50% { transform: translateX(3px) rotate(0deg); }
          75% { transform: translateY(2px) rotate(-1deg); }
          100% { transform: translateX(-3px) rotate(-1deg); }
        }
      `}
                  </style>

                  {/* Robot Image above Battle Bot Session */}
                  <div className="text-center mb-2 mb-md-3 position-relative">
                    <img
                      src={images.robo}
                      alt="Battle Robot"
                      className="img-fluid mx-auto"
                      style={{
                        maxWidth: "clamp(150px, 15vw, 200px)", // Responsive sizing
                        height: "auto", // Default height
                        maxHeight: "clamp(150px, 20vh, 200px)", // Responsive height: 150px mobile, 200px large screens
                        width: "auto", // Allow width to adjust
                        filter: "drop-shadow(0 0 5px rgba(0, 255, 255, 0.4))", // Reduced shadow
                        animation: "robotHover 3s ease-in-out infinite",
                        transform: "translateZ(0)",
                        zIndex: 2,
                        position: "relative",
                      }}
                    />
                    <div
                      className="position-absolute w-100 h-100 top-0 start-0"
                      style={{
                        background:
                          "radial-gradient(circle, rgba(0,255,255,0.05) 0%, rgba(0,0,0,0) 70%)", // Reduced glow
                        zIndex: 1,
                      }}
                    ></div>

                    <style>
                      {`
      @keyframes robotHover {
        0% { transform: translateY(0); }
        50% { transform: translateY(-5px); } /* Reduced movement */
        100% { transform: translateY(0); }
      }
      
      /* Media query for large screens */
      @media (min-width: 992px) {
        .battle-robot-img {
          height: 200px !important;
          width: auto !important;
        }
      }
    `}
                    </style>
                    <div
                      className="position-absolute"
                      style={{
                        bottom: "-5px", // Moved up
                        left: "50%",
                        transform: "translateX(-50%)",
                        width: "70%", // Smaller shadow
                        height: "5px", // Thinner shadow
                        background:
                          "radial-gradient(ellipse, rgba(0, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 70%)", // More subtle
                        borderRadius: "50%",
                        filter: "blur(1px)", // Less blur
                        zIndex: 1,
                      }}
                    ></div>
                  </div>

                  <div className="d-flex align-items-center mb-1">
                    <div
                      className="me-2 d-flex justify-content-center align-items-center rounded-circle"
                      style={{
                        width: "40px", // Smaller for mobile
                        height: "40px", // Smaller for mobile
                        flexShrink: 0,
                        background: "linear-gradient(135deg, #00ffff, #0088ff)",
                        boxShadow:
                          "0 0 8px #00ffff, inset 0 0 5px rgba(255, 255, 255, 0.5)", // Reduced shadow
                      }}
                    >
                      <i
                        className="fas fa-robot text-dark"
                        style={{ fontSize: "1.2rem" }}
                      ></i>
                    </div>
                    <h2
                      className="h4 my-3 my-md-3 fw-bold"
                      style={{
                        fontSize: "clamp(1.1rem, 3vw, 1.5rem)", // Smaller on mobile
                        color: "#00ffff",
                      }}
                    >
                      Battle Day
                    </h2>
                  </div>

                  <div
                    className="ms-2 ps-2 mt-1"
                    style={{ borderLeft: "2px solid #00ffff" }}
                  >
                    <p
                      className="lead mb-1 d-flex align-items-center"
                      style={{ fontSize: "clamp(0.8rem, 2vw, 1rem)" }}
                    >
                      <i
                        className="far fa-calendar-alt me-2 text-warning"
                        style={{
                          fontSize: "1rem",
                          width: "16px",
                          textShadow: "0 0 4px rgba(255, 193, 7, 0.5)", // Reduced shadow
                        }}
                      ></i>
                      <span
                        className="fw-bold text-warning"
                        style={{ textShadow: "0 0 4px rgba(255, 193, 7, 0.5)" }}
                      >
                        March 30th, 2025
                      </span>
                    </p>
                    <p
                      className="lead mb-0 d-flex align-items-center"
                      style={{ fontSize: "clamp(0.8rem, 2vw, 1rem)" }}
                    >
                      <i
                        className="fas fa-ticket-alt me-2 text-warning"
                        style={{
                          fontSize: "1rem",
                          width: "16px",
                          textShadow: "0 0 4px rgba(255, 193, 7, 0.5)", // Reduced shadow
                        }}
                      ></i>
                      <span>
                        <span
                          className="fw-bold text-warning"
                          style={{
                            textShadow: "0 0 4px rgba(255, 193, 7, 0.5)",
                          }}
                        >
                          Entrance Free
                        </span>
                        <span className="d-none d-sm-inline">
                          {" "}
                          - Limited Spots Available!
                        </span>
                      </span>
                    </p>
                  </div>

                  <div
                    className="mt-2 p-1 my-2 rounded text-center"
                    style={{
                      background: "rgba(0, 0, 0, 0.6)",
                      borderTop: "1px solid rgba(0, 255, 255, 0.3)",
                      borderBottom: "1px solid rgba(0, 255, 255, 0.3)",
                    }}
                  >
                    <p
                      className="mb-0 fw-bold"
                      style={{
                        color: "#FF0000",
                        textShadow: "0 0 4px rgb(252, 58, 58)", // Reduced shadow
                        letterSpacing: "1px",
                        fontSize: "clamp(0.8rem, 2vw, 1rem)",
                      }}
                    >
                      <i
                        className="fas fa-bolt me-1 me-sm-2"
                        style={{ color: "#FF0000" }}
                      ></i>
                      Don't miss this exciting robotic competition!
                    </p>
                  </div>

                  {/* Register Now Button inside Battle Bot Section */}
                  <div className="text-center mt-2 mt-md-3">
                    <a
                      href="https://forms.gle/ZZ3prLhFnijbCMr77"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn text-uppercase fw-bold py-1 py-sm-2 px-2 px-sm-3 d-inline-block"
                      style={{
                        background: "linear-gradient(135deg, #00ffff, #0088ff)",
                        borderRadius: "20px", // Smaller radius
                        border: "none",
                        color: "#000",
                        boxShadow:
                          "0 0 8px rgba(0, 255, 255, 0.5), 0 0 12px rgba(0, 255, 255, 0.3)", // Reduced shadow
                        transition: "all 0.3s ease",
                        fontSize: "clamp(0.8rem, 1.8vw, 1rem)",
                        transform: "translateZ(0)",
                        textDecoration: "none",
                      }}
                    >
                      <i className="fas fa-user-plus me-1"></i>
                      <span className="d-inline">REGISTER NOW</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Powered by SLT - Right Bottom Corner */}
      <div
        className="position-absolute d-flex align-items-center"
        style={{
          bottom: "10px",
          right: "10px",
          zIndex: 2,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          padding: "5px 10px",
          borderRadius: "5px",
          "@media (min-width: 768px)": {
            bottom: "20px",
            right: "30px",
            padding: "8px 15px",
          },
        }}
      >
        <span
          className="me-1 me-sm-2"
          style={{ fontSize: "clamp(0.75rem, 2vw, 1rem)" }}
        >
          Powered by
        </span>
        <img
          src={images.slt}
          alt="SLT"
          className="img-fluid"
          style={{
            height: "clamp(25px, 6vw, 40px)",
            width: "clamp(65px, 15vw, 100px)",
          }}
        />
      </div>
    </header>
  );
};

export default Header;