import React from 'react';

function FAQSection() {
  
  const faqDataSchool = [
    {
      question:
        'Webots terminal shows this warning - "WARNING: The current physics step could not be computed correctly. Your world may be too complex. If this problem persists, try simplifying your bounding object(s), reducing the number of joints, or reducing WorldInfo.basicTimeStep.". What do we do?',
      answer:
        "To address this warning, you can reduce the basicTimeStep value under WorldInfo in the Scene Tree. A value between 8 and 16 is typically suitable for most computers. It's important to note that adjusting this value will not affect the task execution time, as every submission will be evaluated using the same basicTimeStep by our evaluators.",
    },
    {
      question: 'How do we create the video for submission?',
      answer:
        'You can create a video for submission using the built-in record button in Webots or by using a screen recording software such as OBS Studio.',
    },
    {
      question: 'Is the video submission compulsory?',
      answer:
        'Yes, a video submission is mandatory. Providing a video alongside your code allows us to verify that the robot performs the same on our evaluation computers as it does on your local machine. If there are discrepancies, we will contact you for instructions on which Python version, C++ version, library versions, etc., to use to ensure consistent performance.',
    },
  ];

  const faqDataUniversity = [
    {
      question:
        'How do you ensure that the robot performs exactly the same on the evaluating computers as it does on my local computer?',
      answer:
        "We rely on the video recording you provide for this purpose. Additionally, if there are specific library versions that need to be used (e.g., OpenCV), please include a requirements.txt file to make it easier for the evaluators to set up your environment. If the evaluators encounter any difficulties in replicating your robot's performance as shown in the video, they will contact you for further instructions.",
    },
    {
      question: 'How can I check the dimensions of the robot?',
      answer:
        'We will place a Solid -> Shape -> Box node with a light semi-transparent color around your robot. This method allows us to verify that no component of the robot extends beyond the boundaries of this box, ensuring it complies with the dimension restrictions.',
    },
    {
      question:
        'Will the dimension restrictions apply if we use a Webots Proto Robot?',
      answer:
        'Yes, when selecting and coding a Webots Proto robot, it is your responsibility to ensure that the robot remains within the specified dimension restrictions during both the start and execution of the task.',
    },
  ];

  return (
    <section id="FAQ" className="my-5 scroll-margin overflow-hidden">
      <div className="container px-3" style={{ margin: 'auto', width: '100%', maxWidth: '1200px' }}>
        <div className="row mt-5 mb-5">
          <div className="col-lg-8 mx-auto text-center text-muted reveal-bottom">
            <h2 className="topic display-6 section-heading text-white text-uppercase">
              FAQ
            </h2>
            <hr />
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-8 mx-auto text-center text-muted reveal-rotate">
            <hr />
            <h2 className="topic lead section-heading text-white text-uppercase">
              School Category
            </h2>
            <hr />
          </div>
        </div>
        <div className="accordion accordion-flush" id="schoolaccordionExample">
          {faqDataSchool.map((item, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`schoolheading${index}`}>
                <button
                  className={`accordion-button faq-background ${
                    index === 0 ? 'show' : 'collapsed'
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#schoolcollapse${index}`}
                  aria-expanded={index === 0 ? 'true' : 'false'}
                  aria-controls={`schoolcollapse${index}`}>
                  {item.question}
                </button>
              </h2>
              <div
                id={`schoolcollapse${index}`}
                className={`accordion-collapse collapse ${
                  index === 0 ? 'show' : ''
                }`}
                aria-labelledby={`schoolheading${index}`}
                data-bs-parent="#schoolaccordionExample">
                <div className="accordion-body faq-body">{item.answer}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="row my-5">
          <div className="col-lg-8 mx-auto text-center text-muted reveal-rotate">
            <hr />
            <h2 className="topic lead section-heading text-white text-uppercase">
              University Category
            </h2>
            <hr />
          </div>
        </div>
        <div className="accordion accordion-flush" id="uniaccordionExample">
          {faqDataUniversity.map((item, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={`uniheading${index}`}>
                <button
                  className={`accordion-button faq-background ${
                    index === 0 ? 'show' : 'collapsed'
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#unicollapse${index}`}
                  aria-expanded={index === 0 ? 'true' : 'false'}
                  aria-controls={`unicollapse${index}`}>
                  {item.question}
                </button>
              </h2>
              <div
                id={`unicollapse${index}`}
                className={`accordion-collapse collapse ${
                  index === 0 ? 'show' : ''
                }`}
                aria-labelledby={`uniheading${index}`}
                data-bs-parent="#uniaccordionExample">
                <div className="accordion-body faq-body">{item.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default FAQSection;
