import React from 'react';

// import homepage sections
import Header from './Header';
import About from './About';
import ChallengeDetails from './ChallengeDetails';


import Timeline from './Timeline';
import Register from './Register';
import Challenge from './Challenge';
import Organize from './Organize';
import Portfolio from './Portfolio';
import Contact from './Contact';
import PrizeDistribution from './PrizeDistribution';

import { handleReveal } from '../javascript/scrolling';
import CountDown from './CountDown';
import Price from './Price';
import FAQSection from './FAQSection';


export class Homepage extends React.Component {
  componentDidMount() {
    handleReveal();
  }

  render() {
    return (
      <div className="main">
        <Header />
        <CountDown />
        <About />
        <ChallengeDetails />
       
       
        <Price />
        <Timeline />
        <FAQSection />
        <Portfolio />
        <Organize />
        <Contact />
      </div>
    );
  }
}

export default Homepage;
