import React, { useEffect, useState } from "react";

const FinalCountdown = () => {
  const calculateTimeLeft = () => {
    let countDownDate = new Date("Mar 30, 2025 09:00:00").getTime();
    let now = new Date().getTime();
    let difference = countDownDate - now;

    let timeleft = { finished: true };

    if (difference > 0) {
      timeleft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
        finished: false,
      };
    }

    return timeleft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    border: "2px solid #fff",
    borderRadius: "10px",
    backgroundColor: "#000",
    boxShadow: "0 0 20px rgba(255, 255, 255, 0.5)",
    color: "#0ff",
    textShadow: "0 0 10px #0ff, 0 0 20px #0ff, 0 0 30px #0ff, 0 0 40px #0ff",
    margin: "10px",
  };

  const labelStyle = {
    marginTop: "10px",
    fontSize: "1.5rem",
    color: "#fff",
  };

  if (timeLeft.finished) {
    return null;
  }

  return (
    <div className="final-countdown">
      <h2>To the Grand Final</h2>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          margin: "auto",
          gap: "30px",
        }}
      >
        <div>
          <div style={boxStyle}>
            <div className="value">
              <span>{timeLeft.days}</span>
            </div>
          </div>
          <span style={labelStyle}>Days</span>
        </div>

        <div>
          <div style={boxStyle}>
            <div className="value">
              <span>{timeLeft.hours}</span>
            </div>
          </div>
          <span style={labelStyle}>Hours</span>
        </div>

        <div>
          <div style={boxStyle}>
            <div className="value">
              <span>{timeLeft.minutes}</span>
            </div>
          </div>
          <span style={labelStyle}>Minutes</span>
        </div>

        <div>
          <div style={boxStyle}>
            <div className="value">
              <span>{timeLeft.seconds}</span>
            </div>
          </div>
          <span style={labelStyle}>Seconds</span>
        </div>
      </div>
    </div>
  );
};

export default FinalCountdown;
